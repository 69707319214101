
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthLayout",
  setup() {
    const redirectToLionwood = () => {
      window.location.href = "https://lionwood.software/";
    };

    return {
      redirectToLionwood,
    };
  },
});
